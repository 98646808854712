import React from 'react'
import PropTypes from 'prop-types'
import { colors, presets } from '../utils/theme'
import CascadeLink from '../components/CascadeLink'
import Metadata from '../components/Metadata'
import frontlineLoan from '../img/Frontline_hero_transparent.svg'
import { Footer, Header } from './lt-landing-page'
import useSiteMetadata from '../components/SiteMetadata'
import { graphql } from 'gatsby'

const PREVIEW_IMAGE_PATH = '/img/LoanSnap_Frontline_preview.png'

const contentWidth = {
  width: "100%",
  maxWidth: '1000px',
  margin: '0 auto',
  padding: '0 20px',
}

const styles = {
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    ...contentWidth,
    maxWidth: '1000px',
    [presets.Tablet]: {
      alignItems: 'flex-start',
    }
  },
  topRow: {
    backgroundColor: colors.darkGreen,
    display: 'flex',
    width: '100%',
    paddingTop: '20px',
  },
  h1: {
    color: 'white',
    marginBottom: 0,
    marginTop: '10px',
    fontSize: '26px',
    lineHeight: '25px',
    fontWeight: 600,
    [presets.Tablet]: {
      fontSize: '36px',
      lineHeight: '36px',
    }
  },
  subtext: {
    margin: '0',
    color: 'white',
    fontWeight: 300,
    fontSize: '18px',
    marginTop: '5px',
    marginBottom: '5px',
  },
  subtextLine: {
    marginTop: '10px',
    marginBottom: '5px',
    maxWidth: '300px',
  },
  button: {
    borderRadius: '5px',
    textDecoration: 'none',
    textAlign: 'center',
    marginBottom: '30px',
    padding: '0 8px 0 8px',
    fontSize: '18px',
    backgroundColor: colors.shamrock,
    color: 'white',
    ':hover': {
      // hack to make dark hover for any color
      boxShadow: 'inset 0 0 0 99999px rgba(0,0,0,0.2)',
    },
    width: '100%',
    maxWidth: '296px',
    height: '43px',
    display: 'flex',
    flexDirection: 'column',
    alignItem: 'center',
    justifyContent: 'center',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'stretch',
    [presets.Tablet]: {
      justifyContent: 'space-between',
    }
  },
  halfRow: {
    display: 'flex',
    flexDirection: 'column',
    flex: 0,
    minWidth: '300px',
    [presets.Tablet]: {
      flex: 1,
    }
  },
  frontlineImage: {
    width: '100%',
    maxWidth: '650px',
    margin: 0,
    // align bottom of F in logo to 3rd line of text
    marginBottom: '62px',
  },
  secondRow: {
    backgroundColor: colors.tan,
    color: colors.grey,
    paddingBottom: '30px',
  },
}

const FrontlineLoanTemplate = ({data}) => {
  const {
    title,
    description,
    subTextLines,
    buttonText,
    targetUrl,
  } = data.markdownRemark.frontmatter

  const { siteUrl } = useSiteMetadata()
  const { htmlAst: disclaimerText='' } = data.markdownRemark

  return (
    <div css={{ display: 'flex', flex: 1, flexDirection: 'column', minHeight: '100vh'}}>
      <Metadata
        title={title}
        description={description}
        image={siteUrl + PREVIEW_IMAGE_PATH}
      />
      <Header/>
      <div css={styles.topRow}>
        <div css={styles.contentContainer}>
          <img alt="frontlineLoan" src={frontlineLoan} css={[styles.frontlineImage, {display: 'flex', maxWidth: '300px', [presets.Tablet]: { display: 'none'}}]}/>
          <div css={styles.row}>
            <div css={styles.halfRow}>
              <div css={styles.subtext}>
                {subTextLines && subTextLines.map((l, i)=> {
                  if (i > 2) {
                    return <div css={styles.subtextLine} key={i}>{l.text}</div>
                  } else {
                    return <h1 css={styles.h1} key={i}>{l.text}</h1>
                  }
                })}
              </div>
              <CascadeLink
                css={[styles.button]}
                href={targetUrl}
                analyticsTargetName='CTA Button'
              >
                { buttonText || "Get Started"}
              </CascadeLink>
            </div>
            <div css={[styles.halfRow, {alignItems: 'center', display: 'none', [presets.Tablet]: { display: 'flex'}}]}>
              <img alt="frontlineLoan" src={frontlineLoan} css={[styles.frontlineImage]}/>
            </div>
          </div>
        </div>
      </div>
      <Footer disclaimerText={disclaimerText}/>
    </div>
  )
}

FrontlineLoanTemplate.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
        subTextLines: PropTypes.array,
        buttonText: PropTypes.string,
        targetUrl: PropTypes.string,
      }),
    }),
  }),
}

export default FrontlineLoanTemplate

export const pageQuery = graphql`
  query FrontlineLandingPageByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      htmlAst
      frontmatter {
        title
        description
        subTextLines {
          text
        }
        buttonText
        targetUrl
      }
    }
  }
`
